import handleResponse from '../helpers/handleResponse';
import constants from '../constants/constants';
import helpers from '../helpers/helpers';

const evertransitApiKey = (window.location.hostname === process.env.REACT_APP_HOSTNAME) ? process.env.REACT_APP_API_KEY : helpers.getParameterByName('api_key');

function getServiceLevels(params) {
  const url = new URL(`${constants.API_PATH}/service-levels`);
  url.search = new URLSearchParams(params);
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'dashride-api-key': evertransitApiKey },
    apiKey: evertransitApiKey,
  };

  return fetch(url, requestOptions).then(handleResponse).catch(err => err);
}

const ServiceLevelService = {
  getServiceLevels,
};

export default ServiceLevelService;
