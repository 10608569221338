import React from 'react';
import Grid from '@material-ui/core/Grid';
import DoneAllOutlined from '@material-ui/icons/DoneAllOutlined';
import { Typography } from '@material-ui/core';
import ScheduleContext from '../schedule/context/schedule-context';
import constants from '../../constants/constants';

const label = constants.Label;

function Confirmation() {
  return (
    <ScheduleContext.Consumer>
      {
        ({ state }) => (
          <React.Fragment>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  <DoneAllOutlined color="primary" />
                  {' '}
                  {label.rideSuccess}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <b>
                    {label.reservationNumber}

                  </b>
                  {': '}
                  {state.reservationNumber}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <b>
                    {label.firstName}

                  </b>
                  {': '}
                  {state.firstName}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <b>
                    {label.lastName}

                  </b>
                  {': '}
                  {state.lastName}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <b>
                    {label.phone}

                  </b>
                  {': '}
                  {state.phone}
                </Typography>
              </Grid>
              { state.email && (
                <Grid item xs={12}>
                  <Typography>
                    <b>
                      {label.email}

                    </b>
                    {': '}
                    {state.email}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography>
                  <b>
                    {label.startTime}

                  </b>
                  {': '}
                  {state.startTime.format('LLL')}
                </Typography>
              </Grid>
              { state.roundTrip && (
                <Grid item xs={12}>
                  <Typography>
                    <b>
                      {label.returnTime}

                    </b>
                    {': '}
                    {state.returnTime.format('LL')}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography>
                  <b>
                    {label.from}

                  </b>
                  {': '}
                  {state.from}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <b>
                    {label.to}

                  </b>
                  {': '}
                  {state.to}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <b>
                    {label.serviceLevel}

                  </b>
                  {': '}
                  {state.serviceLevel.name}
                </Typography>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
    </ScheduleContext.Consumer>
  );
}

export default Confirmation;
