import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import LoadMapContext from './context/loadMapContext';

class Autocomplete extends Component {
    static contextType = LoadMapContext;

    constructor(props) {
      super(props);
      this.handleMapLoad = this.handleMapLoad.bind(this);
    }


    componentWillMount() {
      const { mapLoaded } = this.context;
      if (mapLoaded) {
        this.handleMapLoad();
      }
    }

    componentDidUpdate() {
      const { mapLoaded } = this.context;
      if (mapLoaded) {
        this.handleMapLoad();
      }
    }

    handleMapLoad() {
      const { idName } = this.props;
      const inputEl = document.getElementById(idName);
      const placesFields = ['geometry', 'icon', 'name', 'formatted_address', 'address_components'];
      /* global google */
      this.autocomplete = new google.maps.places.Autocomplete(inputEl);
      this.autocomplete.setFields(placesFields);
      this.autocomplete.addListener('place_changed', this.handlePlaceSelect.bind(this));
    }

    handlePlaceSelect() {
      const { onPlaceSelect } = this.props;
      const place = this.autocomplete.getPlace();
      if (place) {
        onPlaceSelect(place);
      }
    }

    render() {
      const {
        idName, placeholder, onPlaceChange, label, error, defaultValue, ref, errorText,
      } = this.props;
      return (
        <FormControl fullWidth>
          <TextField
            error={error}
            fullWidth
            required
            name={idName}
            id={idName}
            placeholder={placeholder}
            label={label}
            onChange={onPlaceChange}
            defaultValue={defaultValue}
            inputRef={ref}
          />
          {error && (<FormHelperText error>{errorText}</FormHelperText>)}
        </FormControl>
      );
    }
}

export default Autocomplete;
