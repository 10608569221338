import React from 'react';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ScheduleContext from '../schedule/context/schedule-context';
import constants from '../../constants/constants';
import { validateInputFieldNumber, validateInputFieldString } from '../../helpers/validationSchema';

const label = constants.Label;
const id = constants.Id;

const ServiceLevel = () => {
  const returnHelperText = (min, max) => {
    if (min && max) {
      return `Type in a value between ${min} and ${max}`;
    }
    if (min && !max) {
      return `Type in a value ${min} or greater`;
    }
    if (!min && !max) {
      return `Type in a value between 0 and ${max}`;
    }

    return '';
  };

  const handleServiceLevelChange = async (event, state) => {
    const { target } = event;
    const { value } = target;
    const { validation } = state;
    let { serviceLevel } = state;
    validation.serviceLevel.invalid = false;
    validation.serviceLevel.touched = true;
    value.inputFields.forEach((inputField) => {
      inputField.value = inputField.defaultValue;
      inputField.invalid = !!((inputField.required && !inputField.value));
      inputField.touched = false;
    });
    serviceLevel = value;
    await state.updateState({ validation, serviceLevel });
    state.validateStep();
  };

  const handleServiceLevelChangeInputField = async (event, index, state) => {
    const { target } = event;
    const { value } = target;
    const { serviceLevel } = state;
    let { passengers } = state;
    const {
      minValue, maxValue, required, inputType, title,
    } = serviceLevel.inputFields[index];
    if (title.toLowerCase() === 'passengers') {
      passengers = value;
    }
    serviceLevel.inputFields[index].value = value;
    const getInputValidation = () => {
      switch (inputType) {
        case constants.InputType.INPUT_TYPE_TEXT_FIELD:
          return validateInputFieldString(value, required);
        case constants.InputType.INPUT_TYPE_NUMBER_STEPPER:
          return validateInputFieldNumber(value, minValue, maxValue, required);
        case constants.InputType.INPUT_TYPE_PICKER:
          return validateInputFieldString(value, required);
        case constants.InputType.INPUT_TYPE_AIRPORT_CHECKBOX:
          return validateInputFieldString(value, required);
        case constants.InputType.INPUT_TYPE_PASSENGERS:
          return validateInputFieldNumber(value, minValue, maxValue, required);
        default:
          return validateInputFieldString(value, required);
      }
    };
    serviceLevel.inputFields[index].invalid = getInputValidation();
    serviceLevel.inputFields[index].touched = true;
    await state.updateState({ serviceLevel, passengers });
    state.validateStep();
  };

  const renderInputFields = (inputField, state, index) => {
    switch (inputField.inputType) {
      case (constants.InputType.INPUT_TYPE_TEXT_FIELD):
        return (
          <TextField
            required={inputField.required}
            id={inputField.title}
            name={inputField.title}
            label={inputField.title}
            fullWidth
            autoComplete={inputField.title}
            value={inputField.value}
            error={inputField.invalid && inputField.touched}
            disabled={state.progress}
            onChange={e => handleServiceLevelChangeInputField(e, index, state)}
          />
        );
      case (constants.InputType.INPUT_TYPE_AIRPORT_CHECKBOX):
        return (
          <TextField
            required={inputField.required}
            id={inputField.title}
            name={inputField.title}
            label={inputField.title}
            fullWidth
            autoComplete={inputField.title}
            value={inputField.value}
            error={inputField.invalid && inputField.touched}
            disabled={state.progress}
            onChange={e => handleServiceLevelChangeInputField(e, index, state)}
          />
        );
      case (constants.InputType.INPUT_TYPE_NUMBER_STEPPER):
        return (
          <TextField
            required={inputField.required}
            id={inputField.title}
            name={inputField.title}
            label={inputField.title}
            fullWidth
            value={inputField.value}
            type="number"
            error={inputField.invalid && inputField.touched}
            disabled={state.progress}
            onChange={e => handleServiceLevelChangeInputField(e, index, state)}
            helperText={returnHelperText(inputField.minValue, inputField.maxValue)}
            InputProps={{
              inputProps: {
                min: (inputField.minValue)
                  ? inputField.minValue : 0,
                max: (inputField.maxValue) ? inputField.maxValue : undefined,
              },
            }}
          />
        );
      case (constants.InputType.INPUT_TYPE_PASSENGERS):
        return (
          <TextField
            required={inputField.required}
            id={inputField.title}
            name={inputField.title}
            label={inputField.title}
            fullWidth
            autoComplete={inputField.title}
            value={inputField.value}
            type="number"
            error={inputField.invalid && inputField.touched}
            disabled={state.progress}
            InputProps={{
              inputProps: {
                min: (inputField.minValue)
                  ? inputField.minValue : 0,
                max: (inputField.maxValue) ? inputField.maxValue : undefined,
              },
            }}
            onChange={e => handleServiceLevelChangeInputField(e, index, state)}
            helperText={returnHelperText(inputField.minValue, inputField.maxValue)}
          />
        );
      case (constants.InputType.INPUT_TYPE_PICKER):
        return (
          <FormControl fullWidth>
            <InputLabel shrink="true" htmlFor={inputField.title} required={inputField.required}>{inputField.title}</InputLabel>
            <Select
              id={inputField.title}
              name={inputField.title}
              fullWidth
              autoComplete={inputField.title}
              value={inputField.value}
              disabled={state.progress}
              error={inputField.invalid && inputField.touched}
              onChange={e => handleServiceLevelChangeInputField(e, index, state)}
            >
              {inputField.values.map(val => <MenuItem key={val} value={val}>{val}</MenuItem>)}
            </Select>
          </FormControl>
        );
      default:
        return <span />;
    }
  };

  return (
    <ScheduleContext.Consumer>
      {
        ({ state }) => (
          <React.Fragment>
            {state.serviceLevels.length > 0 && (
              <Grid container spacing={24}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel shrink="true" htmlFor={id.serviceLevel} required>{label.serviceLevel}</InputLabel>
                    <Select
                      disabled={state.progress}
                      id={id.serviceLevel}
                      name={id.serviceLevel}
                      value={state.serviceLevel.name}
                      renderValue={() => state.serviceLevel.name}
                      required
                      fullWidth
                      error={state.validation.serviceLevel.invalid && state.validation.serviceLevel.touched}
                      onChange={e => handleServiceLevelChange(e, state)}
                    >
                      {state.serviceLevels.map(serviceLevel => (
                        <MenuItem
                          key={serviceLevel._id}
                          value={serviceLevel}
                        >
                          {serviceLevel.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <React.Fragment>
                  {
                state.serviceLevel.inputFields && state.serviceLevel.inputFields.map(
                  (inputField, index) => (

                    <Grid item xs={12} sm={6}>
                      {renderInputFields(inputField, state, index)}

                    </Grid>
                  ),
                )
              }
                </React.Fragment>

              </Grid>
            )}
          </React.Fragment>
        )}
    </ScheduleContext.Consumer>
  );
};

export default ServiceLevel;
