import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import InputLabel from '@material-ui/core/InputLabel';
import ScheduleContext from '../schedule/context/schedule-context';
import constants from '../../constants/constants';

const label = constants.Label;
const id = constants.Id;
const helperTexts = constants.HelperTexts;
const placeholders = constants.Placeholders;
const numberFormat = (props) => {
  const {
    inputRef, onChange, rows, name, ...other
  } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      format={rows}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
            name,
          },
        });
      }}
    />
  );
};

function Payment() {
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const getYears = () => {
    const years = [];
    const currentYear = moment().year();
    for (let i = 0; i < 10; i += 1) {
      years.push(currentYear + i);
    }
    return years;
  };

  return (
    <ScheduleContext.Consumer>
      {
        ({ state }) => (
          <React.Fragment>
            <Grid container spacing={24}>

              <Grid item xs={12} md={6}>
                <TextField
                  id={id.card_number}
                  name={id.card_number}
                  label={label.card_number}
                  value={state.card_number}
                  onChange={state.handleChange}
                  error={state.validation.card_number.invalid && state.validation.card_number.touched}
                  InputProps={{
                    inputComponent: numberFormat,
                    rows: placeholders.card_number,
                    name: id.card_number,
                  }}
                  disabled={state.progress}
                  placeholder={placeholders.card_number}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id={id.card_cvc}
                  name={id.card_cvc}
                  label={label.card_cvc}
                  value={state.card_cvc}
                  helperText={helperTexts.card_cvc}
                  onChange={state.handleChange}
                  disabled={state.progress}
                  error={state.validation.card_cvc.invalid && state.validation.card_cvc.touched}
                  InputProps={{
                    inputComponent: numberFormat,
                    rows: placeholders.card_cvc,
                    name: id.card_cvc,
                  }}
                  placeholder={placeholders.card_cvc}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel htmlFor={id.exp_month} required>{label.card_exp_month}</InputLabel>
                <Select
                  required
                  value={state.card_exp_month}
                  id={id.card_exp_month}
                  name={id.card_exp_month}
                  onChange={state.handleChange}
                  disabled={state.progress}
                  renderValue={() => state.card_exp_month}
                  error={state.validation.card_exp_month.invalid && state.validation.card_exp_month.touched}
                  fullWidth
                >
                  {months.map(month => <MenuItem key={month} value={month}>{month}</MenuItem>)}
                </Select>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel htmlFor={id.exp_year} required>{label.card_exp_year}</InputLabel>
                <Select
                  required
                  value={state.card_exp_year}
                  id={id.card_exp_year}
                  name={id.card_exp_year}
                  onChange={state.handleChange}
                  disabled={state.progress}
                  error={state.validation.card_exp_year.invalid && state.validation.card_exp_year.touched}
                  renderValue={() => state.card_exp_year}
                  fullWidth
                >
                  {getYears().map(year => <MenuItem key={year} value={year}>{year}</MenuItem>)}
                </Select>
              </Grid>

            </Grid>
          </React.Fragment>
        )}
    </ScheduleContext.Consumer>
  );
}

export default Payment;
