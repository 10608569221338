import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import moment from 'moment';
import LinearProgress from '@material-ui/core/LinearProgress';
import Bowser from 'bowser';
import ScheduleContext from './context/schedule-context';
import ServiceLevelService from '../../services/serviceLevels.service';
import styles from '../../styles/styles';
import Payment from '../payment/Payment';
import Address from '../address/Address';
import ServiceLevel from '../serviceLevel/ServiceLevel';
import RideService from '../../services/ride.service';
import validate from '../../helpers/validationSchema';
import constants from '../../constants/constants';
import Confirmation from '../confirmation/Confirmation';
import Quote from '../quote/Quote';

const label = constants.Label;
const steps = [label.rideDetails, label.paymentDetails];
const browser = Bowser.getParser(window.navigator.userAgent);

class Schedule extends Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      startTime: moment(),
      returnTime: moment({ hour: 23, minute: 59, seconds: 59 }),
      from: '',
      to: '',
      startLoc: [],
      destLoc: [],
      group: '',
      serviceLevel: {},
      serviceLevels: [],
      card_number: '',
      card_exp_month: '',
      card_exp_year: '',
      card_cvc: '',
      roundTrip: false,
      tripNotification: false,
      passengers: 1,
      reservationNumber: '',
      quote: {},
      device: {
        type: 'web',
        friendlyName: browser.getOSName(),
        osVersion: browser.getOSVersion(),
        appName: 'widget',
        browser: {
          name: browser.getBrowserName(),
          version: browser.getBrowserVersion(),
        },
      },
      validation: {
        firstName: {
          invalid: true,
          touched: false,
        },
        lastName: {
          invalid: true,
          touched: false,
        },
        phone: {
          invalid: true,
          touched: false,
        },
        email: {
          invalid: false,
          touched: false,
        },
        startTime: {
          invalid: false,
          touched: false,
        },
        returnTime: {
          invalid: false,
          touched: false,
        },
        from: {
          invalid: true,
          touched: false,
        },
        to: {
          invalid: true,
          touched: false,
        },
        serviceLevel: {
          invalid: true,
          touched: false,
        },
        card_number: {
          invalid: true,
          touched: false,
        },
        card_exp_month: {
          invalid: true,
          touched: false,
        },
        card_exp_year: {
          invalid: true,
          touched: false,
        },
        card_cvc: {
          invalid: true,
          touched: false,
        },

      },
      progress: true,
      activeStep: 0,
      invalidStep: true,
      hasUnhandledError: false,
      hasError: false,
      errorMessage: '',
      quoteModal: false,
      handleChange: this.handleChange,
      validateStep: this.validateStep,
      updateState: this.updateState,
      getServiceLevels: this.getServiceLevels,
      handleQuoteModalClose: this.handleQuoteModalClose,
    };
  }

  componentWillMount() {
    this.getServiceLevels();
  }

  getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <span>
            <Address />
            <br />
            <ServiceLevel />
          </span>
        );
      case 1:
        return <Payment />;
      case 2:
        return <Confirmation />;
      default:
        return null;
    }
  };

  handleNext = async () => {
    await this.setState(state => ({
      activeStep: state.activeStep + 1,
    }));
    this.validateStep();
  };

  handleBack = async () => {
    await this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
    this.validateStep();
  };

  handleReset = async () => {
    await this.setState({
      activeStep: 0,
    });
    this.validateStep();
  };

  handleChange = async (event) => {
    const { target } = event;
    if (target) {
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const { name } = target;
      const { validation } = this.state;
      if (validation[name]) {
        validation[name].touched = true;
        validation[name].invalid = validate({ [name]: value });
      }
      await this.setState({
        [name]: value,
        validation,
      });
      this.validateStep();
    }
  };

  handleQuoteModalClose = async () => {
    await this.setState({
      quoteModal: false,
    });
  }

  updateState = async (value) => {
    await this.setState({ ...value });
  }

  getServiceLevels = async () => {
    const { startLoc, validation } = this.state;
    validation.from.invalid = false;
    await this.setState({
      serviceLevel: {},
      serviceLevels: [],
      progress: true,
      validation,
    });
    const params = (startLoc.length === 2) ? { lat: startLoc[0], long: startLoc[1] } : { lat: '', long: '' };

    ServiceLevelService.getServiceLevels(params).then((response) => {
      if (response.group) {
        this.setState({
          group: response.group,
        });
      }
      if (response.serviceLevels) {
        validation.from.invalid = false;
        this.setState({
          serviceLevels: response.serviceLevels,
          validation,
        });
      }
      if (startLoc.length === 2 && response.serviceLevels.length === 0) {
        validation.from.invalid = true;
        validation.from.touched = true;
        this.setState({
          validation,
        });
      }
      this.setState({
        progress: false,
      });
    }).catch(() => {
      this.setState({
        progress: false,
      });
    });
  }

  getQuote = () => {
    const {
      startTime, from, to, startLoc, destLoc, passengers, serviceLevel, group,
    } = this.state;
    const quote = {
      startTime, from, startLoc, passengers, serviceLevel: serviceLevel._id, group,
    };
    if (destLoc.length === 2) {
      quote.destLoc = destLoc;
      quote.to = to;
    }
    RideService.getQuote(quote).then((result) => {
      this.setState({
        quote: result,
        quoteModal: true,
      });
    }).catch(() => {
    });
  }

  validateStep= async () => {
    const { activeStep, validation, serviceLevel } = this.state;
    let { invalidStep } = this.state;
    switch (activeStep) {
      case 0:
        invalidStep = validation.firstName.invalid || validation.lastName.invalid || validation.email.invalid
              || validation.phone.invalid || validation.from.invalid || validation.to.invalid
              || validation.startTime.invalid || validation.returnTime.invalid
                || validation.serviceLevel.invalid || this.validateServiceLevelInput(serviceLevel.inputFields);
        break;
      case 1:
        invalidStep = validation.card_number.invalid || validation.card_exp_month.invalid
              || validation.card_exp_year.invalid || validation.card_cvc.invalid;
        break;
      default:
        invalidStep = true;
    }
    await this.setState({
      invalidStep,
    });
  }

  validateServiceLevelInput = (inputFields) => {
    let isInvalid = false;
    if (inputFields) {
      inputFields.forEach((inputField) => {
        if (inputField.invalid) {
          isInvalid = true;
          return isInvalid;
        }
      });
    }
    return isInvalid;
  }

  reloadPage = () => {
    window.location.reload();
  }

  componentDidCatch() {
    this.setState({ hasUnhandledError: true });
  }

  async submitForm(e) {
    e.preventDefault();
    const { state } = this;
    this.setState({
      progress: true,
      hasError: false,
      errorMessage: '',
    });
    const extras = {
      names: [],
      values: [],
    };
    if (state.serviceLevel.inputFields.length > 0) {
      state.serviceLevel.inputFields.forEach((inputField) => {
        if ((inputField.title.toLowerCase() !== 'passengers') && inputField.value) {
          extras.names.push(inputField.title);
          extras.values.push(inputField.value);
        }
      });
    }
    const ride = {
      firstName: state.firstName,
      lastName: state.lastName,
      phone: state.phone,
      email: state.email,
      startTime: state.startTime.format(),
      from: state.from,
      to: state.to,
      startLoc: state.startLoc,
      destLoc: state.destLoc,
      group: state.group,
      serviceLevel: state.serviceLevel._id,
      card: {
        number: state.card_number,
        exp_month: state.card_exp_month,
        exp_year: state.card_exp_year,
        cvc: state.card_cvc,
      },
      device: state.device,
      passengers: state.passengers,
      extras,
    };
    if (state.roundTrip) {
      ride.returnTime = state.returnTime.format();
    }
    RideService.createRide(ride)
      .then((res) => {
        if (res.reservationNumber) {
          this.setState({
            progress: false,
            reservationNumber: res.reservationNumber,
          });
          this.handleNext();
        } else {
          const error = 'Some error occured. Please try again.';
          throw Promise.reject(error);
        }
      })
      .catch((err) => {
        this.setState({
          progress: false,
          hasError: true,
          errorMessage: err,
        });
      });
  }

  render() {
    const { classes } = this.props;
    const {
      activeStep, group, serviceLevel, progress, invalidStep, hasUnhandledError, hasError, errorMessage, quoteModal,
    } = this.state;
    return (
      <ScheduleContext.Provider value={{ state: this.state }}>
        <React.Fragment>
          {hasUnhandledError && (
            <Typography variant="h6" align="center">
              {label.unhandledError}
            </Typography>
          )}
        </React.Fragment>
        {!hasUnhandledError && (
          <React.Fragment>
            <main className={classes.layout}>
              <Paper className={classes.paper}>
                <Typography component="h1" variant="h4" className={classes.title} align="center">
                  {label.scheduleARide}
                </Typography>
                {progress && (<LinearProgress />)}
                {!progress && !group && (
                <Typography variant="h6" align="center">
                  {label.unauthorized}
                </Typography>
                )}
                {hasError && (
                <Typography className={classes.dangerBox} align="center">
                  {label.errorLabel}
                  {errorMessage}
                </Typography>
                )}
                {group && (
                <React.Fragment>
                  <Stepper activeStep={activeStep} className={classes.stepper}>
                    {steps.map(_label => (
                      <Step key={_label}>
                        <StepLabel>{_label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <form className="form" onSubmit={this.submitForm}>

                    {this.getStepContent(activeStep)}
                    <div className={classes.buttons}>
                      {serviceLevel.name && activeStep < 2 && (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={this.getQuote}
                        className={classes.quoteButton}
                        disabled={progress}
                      >
                        {label.quote}
                      </Button>
                      )}
                      {activeStep === 0 && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleNext}
                        className={classes.button}
                        disabled={invalidStep || progress}
                      >
                        {label.next}
                      </Button>
                      )}

                      {activeStep === 1 && (
                      <span>
                        <Button
                          onClick={this.handleBack}
                          className={classes.button}
                          disabled={progress}
                        >
                          {label.back}
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          disabled={invalidStep || progress}
                          type="submit"
                        >
                          {label.bookRide}
                        </Button>
                      </span>
                      )}

                      {activeStep === 2 && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.reloadPage}
                        className={classes.button}
                      >
                        {label.bookAnotherRide}
                      </Button>
                      )}
                    </div>
                    {quoteModal && (
                      <React.Fragment>
                        <Quote classes={classes} />
                      </React.Fragment>
                    )}
                  </form>
                </React.Fragment>
                )}
              </Paper>
            </main>
          </React.Fragment>
        )}
      </ScheduleContext.Provider>
    );
  }
}
export default withStyles(styles)(Schedule);
