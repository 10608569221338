export default {
  API_PATH: process.env.REACT_APP_API_URL,
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  InputType: {
    INPUT_TYPE_TEXT_FIELD: 0,
    INPUT_TYPE_NUMBER_STEPPER: 1,
    INPUT_TYPE_PICKER: 3,
    INPUT_TYPE_AIRPORT_CHECKBOX: 5,
    INPUT_TYPE_PASSENGERS: 6,
  },
  Label: {
    firstName: 'First name',
    lastName: 'Last name',
    phone: 'Phone number',
    email: 'Email',
    startTime: 'Pick-up date',
    returnTime: 'Return date',
    from: 'Pick-up location',
    to: 'Drop-off location',
    serviceLevel: 'Service type',
    card_number: 'Credit card number',
    card_exp_month: 'Expiration month',
    card_exp_year: 'Expiration year',
    card_cvc: 'Security code',
    roundTrip: 'Return trip',
    tripNotification: 'Recieve trip notification',
    rideDetails: 'Ride details',
    paymentDetails: 'Payment details',
    back: 'Back',
    next: 'Next',
    scheduleARide: 'Book a Ride',
    bookRide: 'Book ride',
    unauthorized: 'Unauthorized',
    unhandledError: 'An error occured. Please refresh the page.',
    errorLabel: 'An error occured while booking the ride: ',
    bookAnotherRide: 'Schedule another ride',
    rideSuccess: 'Your ride has been scheduled',
    reservationNumber: 'Reservation number',
    pickupTime: 'Pick-up time',
    quote: 'Quote',
    estimatedFare: 'Estimated fare',
  },
  Id: {
    firstName: 'firstName',
    lastName: 'lastName',
    phone: 'phone',
    email: 'email',
    startTime: 'startTime',
    returnTime: 'returnTime',
    from: 'from',
    to: 'to',
    serviceLevel: 'serviceLevel',
    card_number: 'card_number',
    card_exp_month: 'card_exp_month',
    card_exp_year: 'card_exp_year',
    card_cvc: 'card_cvc',
    roundTrip: 'roundTrip',
    tripNotification: 'tripNotification',
  },
  HelperTexts: {
    card_cvc: 'Last 3 digits on signature strip (4 digits for American Express)',
  },
  Placeholders: {
    card_cvc: '####',
    card_number: '#### #### #### ####',
    phone: '(###) ###-####',
    email: 'optional',
    from: 'Enter a pickup location',
    to: 'Enter a destination location',
  },
  ErrorTexts: {
    from: 'No service type is available for this address',
  },
};
