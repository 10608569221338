import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ScheduleContext from '../schedule/context/schedule-context';
import constants from '../../constants/constants';

const label = constants.Label;

function Quote(props) {
  const { classes } = props;
  const displayQuote = (quote) => {
    const { pricing } = quote;
    const currencySymbol = '$';

    let distanceUnit;
    let distanceMultiplier;
    if (pricing.rateDistanceUnit === 1) {
      distanceUnit = 'km';
      distanceMultiplier = 1000;
    } else {
      distanceUnit = 'mi';
      distanceMultiplier = 1609.3;
    }

    const distanceMeters = (quote.distance / distanceMultiplier);
    const durationMinutes = Math.ceil(quote.duration / 60);

    const baseFare = currencySymbol + (pricing.baseFare / 100).toFixed(2);

    const lineItemDescriptions = ['Base fare'];
    const lineItemCosts = [baseFare];

    if (pricing.rateDistance) {
      const rateDistance = currencySymbol + (pricing.rateDistance / 100).toFixed(2);
      const rateDistanceLabel = pricing.rateDistanceUnit === 1 ? 'Rate per km' : 'Rate per mile';

      lineItemDescriptions.push(rateDistanceLabel);
      lineItemCosts.push(rateDistance);
    }

    if (pricing.rateDuration) {
      const rateDuration = currencySymbol + (pricing.rateDuration / 100).toFixed(2);
      const { rateDurationPrecision } = pricing;
      let rateDurationLabel;
      if (rateDurationPrecision === 60) {
        rateDurationLabel = 'Rate per minute';
      } else if (rateDurationPrecision === 900) {
        rateDurationLabel = 'Rate per 15 mins';
      } else if (rateDurationPrecision === 1800) {
        rateDurationLabel = 'Rate per 30 mins';
      } else {
        rateDurationLabel = 'Rate per hour';
      }

      lineItemDescriptions.push(rateDurationLabel);
      lineItemCosts.push(rateDuration);
    }

    if (pricing.ratePerPassenger) {
      const ratePerPassenger = currencySymbol + (pricing.ratePerPassenger / 100).toFixed(2);
      const ratePerPassengerLabel = 'Rate per pax';

      lineItemDescriptions.push(ratePerPassengerLabel);
      lineItemCosts.push(ratePerPassenger);
    }

    if (pricing.minimumFare) {
      const minFare = currencySymbol + (pricing.minimumFare / 100).toFixed(2);

      lineItemDescriptions.push('Minimum fare');
      lineItemCosts.push(minFare);
    }

    const subtotal = currencySymbol + (quote.subtotal / 100).toFixed(2);

    const additionalChargeDescriptions = [
      'Subtotal',
    ];
    const additionalChargeAmounts = [
      subtotal,
    ];

    if (quote.surcharge) {
      const surcharge = currencySymbol + (quote.surcharge / 100).toFixed(2);

      additionalChargeDescriptions.push('Surcharges');
      additionalChargeAmounts.push(surcharge);
    }

    if (quote.tax) {
      additionalChargeDescriptions.push('Tax');
      additionalChargeAmounts.push(currencySymbol + (quote.tax / 100).toFixed(2));
    }

    if (quote.tip) {
      additionalChargeDescriptions.push('Tip');
      additionalChargeAmounts.push(currencySymbol + (quote.tip / 100).toFixed(2));
    }

    if (quote.fee) {
      additionalChargeDescriptions.push('Fee');
      additionalChargeAmounts.push(currencySymbol + (quote.fee / 100).toFixed(2));
    }

    if (quote.discount) {
      additionalChargeDescriptions.push('Discount');
      additionalChargeAmounts.push(`- ${currencySymbol}${(quote.discount / 100).toFixed(2)}`);
    }
    const total = currencySymbol + (quote.total / 100).toFixed(2);

    return (
      <span>
        <br />
        <Typography align="center">
          <b>{`${distanceMeters.toFixed(2)} ${distanceUnit}`}</b>
        </Typography>

        <Typography align="center">
          <b>{`${durationMinutes.toFixed(0)} minutes`}</b>
        </Typography>
        <br />
        {additionalChargeDescriptions.map((element, index) => (
          <div className={classes.lineItems}>
            <span className={classes.lineItemsLeft}>
              {`${additionalChargeDescriptions[index]}:`}
            </span>
            <span className={classes.lineItemsRight}>
              {`${additionalChargeAmounts[index]}`}
            </span>
          </div>
        ))}
        <Typography variant="h6" align="left">
          {`${label.estimatedFare}:  ${total}`}
        </Typography>
      </span>
    );
  };
  return (
    <ScheduleContext.Consumer>
      {
        ({ state }) => (
          <React.Fragment>
            <Dialog onClose={state.handleQuoteModalClose} open={state.quoteModal}>
              <DialogTitle align="center" className={classes.dialogTitle}>
                <Typography variant="h5">{label.quote}</Typography>
                <IconButton aria-label="Close" className={classes.closeButton} onClick={state.handleQuoteModalClose}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                {displayQuote(state.quote)}
              </DialogContent>
            </Dialog>
          </React.Fragment>
        )}
    </ScheduleContext.Consumer>
  );
}

export default Quote;
