import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Schedule from '../schedule/Schedule';
import LoadMapScript from '../map/LoadMapScript';
import { theme } from '../../styles/styles';

const Layout = () => (
  <MuiThemeProvider theme={theme}>
    <LoadMapScript>
      <Schedule />
    </LoadMapScript>
  </MuiThemeProvider>
);
export default Layout;
