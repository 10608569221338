import Joi from '@hapi/joi';
import JoiPhoneNumber from 'joi-phone-number';
import moment from 'moment';
import JoiDate from '@hapi/joi-date';

const year = moment().year();
const schema = Joi.object().keys({
  firstName: Joi.string().min(1).max(100).required(),
  lastName: Joi.string().min(1).max(100).required(),
  phone: Joi.extend(JoiPhoneNumber).string().phoneNumber().min(12)
    .required(),
  email: Joi.string().email({ minDomainSegments: 2 }).allow('').optional(),
  startTime: Joi.extend(JoiDate).date().min('now').format('YYYY-MM-DDThh:mm'),
  from: Joi.string().min(1).required(),
  to: Joi.string().min(1).required(),
  card_number: Joi.string().creditCard().required(),
  returnTime: Joi.extend(JoiDate).date().min('now').format('YYYY-MM-DD')
    .optional(),
  startLoc: Joi.array().min(2).required(),
  destLoc: Joi.array().min(2).required(),
  group: Joi.string().regex(/^[0-9a-fA-F]{24}$/).required(),
  serviceLevel: Joi.string().regex(/^[0-9a-fA-F]{24}$/).required(),
  card_cvc: Joi.string().regex(/^[0-9]{3,4}$/)
    .required(),
  card_exp_month: Joi.number().integer().min(1).max(12)
    .required(),
  card_exp_year: Joi.number().integer().min(year).max(year + 10)
    .required(),
});


function validate(value) {
  const key = Object.keys(value).pop().toString();
  const subschema = Joi.reach(schema, key);
  const result = Joi.validate(Object.values(value).pop(), subschema);
  return !!(result.error);
}

export function validateInputFieldNumber(value, minimum, maximum, required) {
  let subSchema;
  if (minimum && maximum && required) {
    subSchema = Joi.object().keys({
      number: Joi.number().integer().min(minimum).max(maximum)
        .required(),
    });
  } else if (minimum && maximum && !required) {
    subSchema = Joi.object().keys({
      number: Joi.number().integer().min(minimum).max(maximum)
        .optional()
        .allow(''),
    });
  } else if (minimum && !maximum && !required) {
    subSchema = Joi.object().keys({
      number: Joi.number().integer().min(minimum).optional()
        .allow(''),
    });
  } else if (!minimum && maximum && !required) {
    subSchema = Joi.object().keys({
      number: Joi.number().integer().min(0).max(maximum)
        .optional()
        .allow(''),
    });
  } else if (minimum && !maximum && required) {
    subSchema = Joi.object().keys({ number: Joi.number().integer().min(minimum).required() });
  } else if (!minimum && maximum && required) {
    subSchema = Joi.object().keys({
      number: Joi.number().integer().min(0).max(maximum)
        .required(),
    });
  } else if (!minimum && !maximum && required) {
    subSchema = Joi.object().keys({
      number: Joi.number().integer()
        .required(),
    });
  } else if (!minimum && !maximum && !required) {
    subSchema = Joi.object().keys({
      number: Joi.number().integer()
        .optional()
        .allow(''),
    });
  }

  const result = Joi.validate({ number: value }, subSchema);
  return !!(result.error);
}

export function validateInputFieldString(value, required) {
  const subSchema = (required) ? Joi.object().keys({ str: Joi.string().required() })
    : Joi.object().keys({ str: Joi.string() });
  const result = Joi.validate({ str: value }, subSchema);
  return !!(result.error);
}


export default validate;
