import handleResponse from '../helpers/handleResponse';
import constants from '../constants/constants';
import helpers from '../helpers/helpers';

const evertransitApiKey = (window.location.hostname === process.env.REACT_APP_HOSTNAME) ? process.env.REACT_APP_API_KEY : helpers.getParameterByName('api_key');

function createRide(ride) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'dashride-api-key': evertransitApiKey },
    body: JSON.stringify(ride),
  };

  return fetch(`${constants.API_PATH}/ride`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (data) {
        return data;
      }

      return null;
    }).catch((err) => { throw err; });
}

function getQuote(params) {
  const url = new URL(`${constants.API_PATH}/ride/quote`);
  url.search = new URLSearchParams(params);
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'dashride-api-key': evertransitApiKey },
    apiKey: evertransitApiKey,
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (data) {
        return data;
      }

      return null;
    }).catch((err) => { throw err; });
}

const RideService = {
  createRide,
  getQuote,
};
export default RideService;
