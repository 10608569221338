import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MuiPhoneNumber from 'material-ui-phone-number';
import MomentUtils from '@date-io/moment';
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from 'material-ui-pickers';
import ScheduleContext from '../schedule/context/schedule-context';
import AutoComplete from '../map/AutoComplete';
import validate from '../../helpers/validationSchema';
import constants from '../../constants/constants';

const label = constants.Label;
const id = constants.Id;
const placeholders = constants.Placeholders;
const errorTexts = constants.ErrorTexts;

function Address() {
  const handlePhoneChange = async (phoneNumber, state) => {
    const value = phoneNumber.replace(/[-()\s]/g, '');
    const { validation } = state;
    let { phone } = state;
    validation.phone.invalid = validate({ phone: value });
    validation.phone.touched = true;
    phone = value;
    await state.updateState({ validation, phone });
    state.validateStep();
  };
  const handleDateChange = async (date, state, dateType, datePicker) => {
    let { startTime, returnTime } = state;
    const { validation } = state;
    if (dateType === id.startTime) {
      const previousTime = startTime.clone();
      startTime = date.clone();
      if (datePicker) {
        startTime = startTime.set({ hour: previousTime.hour(), minute: previousTime.minute(), seconds: previousTime.seconds() });
      }
      validation.startTime.invalid = validate({ startTime: date.toDate() });
      validation.startTime.touched = true;
      if (returnTime < startTime) {
        returnTime = date.clone().set({ hour: 23, minute: 59, seconds: 59 });
        validation.returnTime.invalid = validate({ returnTime: date.toDate() });
        validation.returnTime.touched = true;
      }
    } else {
      returnTime = date.clone();
      validation.returnTime.invalid = validate({ returnTime: date.toDate() });
      validation.returnTime.touched = true;
    }
    await state.updateState({ startTime, returnTime, validation });
    state.validateStep();
  };
  const handleStartLocationChange = async (place, state) => {
    const { validation } = state;
    let { from, startLoc } = state;
    validation.from.touched = true;
    if (place && place.geometry) {
      validation.from.invalid = false;
      from = document.getElementById(id.from).value;
      startLoc = [place.geometry.location.lat(), place.geometry.location.lng()];
    } else {
      validation.from.invalid = true;
      from = '';
      startLoc = [];
    }
    await state.updateState({ validation, from, startLoc });
    state.validateStep();
    state.getServiceLevels();
  };

  const handleDestLocationChange = async (place, state) => {
    const { validation } = state;
    let { to, destLoc } = state;
    validation.to.touched = true;
    if (place && place.geometry) {
      validation.to.invalid = false;
      to = document.getElementById(id.to).value;
      destLoc = [place.geometry.location.lat(), place.geometry.location.lng()];
    } else {
      validation.to.invalid = true;
      to = '';
      destLoc = [];
    }
    await state.updateState({ validation, to, destLoc });
    state.validateStep();
  };
  return (
    <ScheduleContext.Consumer>
      {
        ({ state }) => (

          <React.Fragment>
            <Grid container spacing={24}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  error={state.validation.firstName.invalid && state.validation.firstName.touched}
                  id={id.firstName}
                  name={id.firstName}
                  label={label.firstName}
                  fullWidth
                  autoComplete={id.firstName}
                  onChange={state.handleChange}
                  value={state.firstName}
                  disabled={state.progress}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  error={state.validation.lastName.invalid && state.validation.lastName.touched}
                  id={id.lastName}
                  name={id.lastName}
                  label={label.lastName}
                  fullWidth
                  autoComplete={id.firstName}
                  onChange={state.handleChange}
                  value={state.lastName}
                  disabled={state.progress}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MuiPhoneNumber
                  required
                  error={state.validation.phone.invalid && state.validation.phone.touched}
                  name={id.phone}
                  id={id.phone}
                  label={label.phone}
                  defaultCountry="us"
                  disableAreaCodes="true"
                  placeholder={placeholders.phone}
                  fullWidth
                  onChange={e => handlePhoneChange(e, state)}
                  value={state.phone}
                  disabled={state.progress}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id={id.email}
                  name={id.email}
                  label={label.email}
                  type="email"
                  fullWidth
                  placeholder={placeholders.email}
                  onChange={state.handleChange}
                  value={state.email}
                  disabled={state.progress}
                  error={state.validation.email.invalid && state.validation.email.touched}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    id={id.startTime}
                    name={id.startTime}
                    value={state.startTime}
                    label={label.startTime}
                    fullWidth
                    required
                    minDate={new Date()}
                    disabled={state.progress}
                    error={state.validation.startTime.invalid && state.validation.startTime.touched}
                    onChange={e => handleDateChange(e, state, id.startTime, true)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <TimePicker
                    id={id.startTime}
                    name={id.startTime}
                    value={state.startTime}
                    label={label.pickupTime}
                    fullWidth
                    minDate={new Date()}
                    required
                    disabled={state.progress}
                    error={state.validation.startTime.invalid && state.validation.startTime.touched}
                    onChange={e => handleDateChange(e, state, id.startTime, false)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={12}>
                <AutoComplete
                  idName={id.from}
                  placeholder={placeholders.from}
                  onPlaceSelect={e => handleStartLocationChange(e, state)}
                  label={label.from}
                  disabled={state.progress}
                  error={state.validation.from.invalid && state.validation.from.touched}
                  defaultValue={state.from}
                  errorText={errorTexts.from}
                />
              </Grid>
              <Grid item xs={12}>
                <AutoComplete
                  idName={id.to}
                  placeholder={placeholders.to}
                  onPlaceSelect={e => handleDestLocationChange(e, state)}
                  label={label.to}
                  disabled={state.progress}
                  error={state.validation.to.invalid && state.validation.to.touched}
                  defaultValue={state.to}
                />
              </Grid>


              <Grid item xs={12}>
                <FormControlLabel
                  control={(
                    <Switch
                      disabled={state.progress}
                      color="primary"
                      checked={state.roundTrip}
                      name={id.roundTrip}
                      onChange={state.handleChange}
                    />
                    )}
                  label={label.roundTrip}
                />
              </Grid>
              {state.roundTrip && (
              <Grid item xs={12} sm={6}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    id={id.returnTime}
                    name={id.returnTime}
                    label={label.returnTime}
                    error={state.validation.returnTime.invalid && state.validation.returnTime.touched}
                    fullWidth
                    value={state.returnTime}
                    minDate={state.startTime}
                    disabled={state.progress}
                    onChange={e => handleDateChange(e, state, id.returnTime, true)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              )}
            </Grid>
          </React.Fragment>
        )}
    </ScheduleContext.Consumer>
  );
}

export default Address;
