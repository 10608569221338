import React, { Component } from 'react';
import Script from 'react-load-script';
import LoadMapContext from './context/loadMapContext';
import constants from '../../constants/constants';
// src/constants
// src/components/map/LoadMapScript.js
const reducer = (state, action) => {
  switch (action.type) {
    case 'MAPLOADED':
      return { ...state, mapLoaded: true };

    default:
      return state;
  }
};

class LoadMapScript extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapLoaded: false,
      dispatch: (action) => {
        this.setState(state => reducer(state, action));
      },
    };
    this.onMapLoad = this.onMapLoad.bind(this);
  }

  onMapLoad() {
    const { dispatch } = this.state;
    this.setState({
      mapLoaded: true,
    });
    dispatch({ type: 'MAPLOADED' });
  }

  render() {
    const { mapLoaded } = this.state;
    const { children } = this.props;
    return (
      <LoadMapContext.Provider value={{ mapLoaded }}>
        <Script
          url={`https://maps.googleapis.com/maps/api/js?key=${constants.GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
          onLoad={this.onMapLoad}
        />
        {children}
      </LoadMapContext.Provider>

    );
  }
}

export default LoadMapScript;
